var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap rf_info_pop",
      staticStyle: { width: "900px", height: "300px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.ONEX050P081.001"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content_box" }, [
          _c("table", { staticClass: "tbl_col" }, [
            _vm._m(0),
            _c("thead", [
              _c("tr", [
                _c("th", { attrs: { scope: "col" } }),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.CSBK100.287"))),
                ]),
                _vm._v(" "),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.CSBK100.272"))),
                ]),
                _vm._v(" "),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.CSBK100.273"))),
                ]),
                _vm._v(" "),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.ONEX010P090.041"))),
                ]),
                _vm._v(" "),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.ONEX010P090.056"))),
                ]),
                _vm._v(" "),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.ONEX010P090.057"))),
                ]),
                _vm._v(" "),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.ONEX010P090.058"))),
                ]),
                _vm._v(" "),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.ONEX010P090.059"))),
                ]),
              ]),
            ]),
            _vm.resultList.length > 0
              ? _c(
                  "tbody",
                  _vm._l(_vm.resultList, function (vo, idx) {
                    return _c("tr", { key: "cntrRf_" + idx }, [
                      _c("td", [
                        _c("input", {
                          staticClass: "rfSelect",
                          attrs: { type: "checkbox", id: "rfSelect_" + idx },
                          domProps: { value: idx },
                        }),
                        _c("label", { attrs: { for: "rfSelect_" + idx } }),
                      ]),
                      _c("td", [_vm._v(_vm._s(idx + 1))]),
                      _c("td", [_vm._v(_vm._s(vo.cntrSzCdNm))]),
                      _c("td", [_vm._v(_vm._s(vo.cntrTypCd))]),
                      _c("td", [_vm._v(_vm._s(vo.cgoTypCdNm))]),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: vo.rfSlecUseYn,
                              expression: "vo.rfSlecUseYn",
                            },
                          ],
                          attrs: {
                            id: "rfSlecUseYn_" + idx,
                            type: "checkbox",
                            "true-value": "Y",
                            "false-value": "N",
                            disabled: "",
                          },
                          domProps: {
                            checked: Array.isArray(vo.rfSlecUseYn)
                              ? _vm._i(vo.rfSlecUseYn, null) > -1
                              : _vm._q(vo.rfSlecUseYn, "Y"),
                          },
                          on: {
                            change: function ($event) {
                              var $$a = vo.rfSlecUseYn,
                                $$el = $event.target,
                                $$c = $$el.checked ? "Y" : "N"
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      vo,
                                      "rfSlecUseYn",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      vo,
                                      "rfSlecUseYn",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(vo, "rfSlecUseYn", $$c)
                              }
                            },
                          },
                        }),
                        _c("label", { attrs: { for: "rfSlecUseYn_" + idx } }),
                      ]),
                      _c("td", [_vm._v(_vm._s(vo.rfSetupTmpr))]),
                      _c("td", [_vm._v(_vm._s(vo.rfTmprUnitCd))]),
                      _vm.cntrPolCtrCd === "VN" && _vm.cntrRfStsYn === "Y"
                        ? _c("td", [
                            _vm._v(_vm._s(_vm.getRfVentCdNmVN(vo.cntrRfStsCd))),
                          ])
                        : _c("td", [
                            _vm._v(_vm._s(_vm.getRfVentCdNm(vo.cntrRfStsCd))),
                          ]),
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _vm.resultList.length === 0
              ? _c("tbody", [
                  _c("tr", [
                    _c("td", { attrs: { colspan: "8" } }, [
                      _vm._v(_vm._s(_vm.$t("msg.CMBA660.005"))),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]),
        _c("div", { staticClass: "text_center mt20" }, [
          _c(
            "a",
            {
              staticClass: "button lg blue font_16",
              on: {
                click: function ($event) {
                  return _vm.fnRFSelect()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.CSBK100.178")))]
          ),
          _c(
            "a",
            {
              staticClass: "button lg gray font_16",
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.CSBK100.179")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "5%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "15%" } }),
      _c("col", { staticStyle: { width: "15%" } }),
      _c("col", { staticStyle: { width: "15%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }