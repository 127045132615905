<template>
  <div class="popup_wrap rf_info_pop" style="width:900px; height:300px;">
    <button class="layer_close" @click="$emit('close')">close</button>

    <div class="popup_cont">
      <h1 class="page_title">{{ $t('msg.ONEX050P081.001') }}</h1> <!-- B/L RF(RH) Entry -->

      <div class="content_box">
        <table class="tbl_col">
          <colgroup>
            <col style="width:5%">
            <col style="width:10%">
            <col style="width:10%">
            <col style="width:10%">
            <col style="width:10%">
            <col style="width:10%">
            <col style="width:15%">
            <col style="width:15%">
            <col style="width:15%">
          </colgroup>
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">{{ $t('msg.CSBK100.287') }}</th> <!-- SEQ -->
              <th scope="col">{{ $t('msg.CSBK100.272') }}</th> <!-- Size -->
              <th scope="col">{{ $t('msg.CSBK100.273') }}</th> <!-- Type -->
              <th scope="col">{{ $t('msg.ONEX010P090.041') }}</th> <!-- CGO -->
              <th scope="col">{{ $t('msg.ONEX010P090.056') }}</th> <!-- Plug -->
              <th scope="col">{{ $t('msg.ONEX010P090.057') }}</th> <!-- Temp -->
              <th scope="col">{{ $t('msg.ONEX010P090.058') }}</th> <!-- C/F -->
              <th scope="col">{{ $t('msg.ONEX010P090.059') }}</th> <!-- vent -->
            </tr>
          </thead>
          <tbody v-if="resultList.length > 0">
            <tr v-for="(vo, idx) in resultList" :key="'cntrRf_' + idx">
              <td>
                <input
                  type="checkbox"
                  :id="'rfSelect_' + idx"
                  class="rfSelect"
                  :value="idx"
                >
                <label :for="'rfSelect_' + idx"></label>
              </td>
              <td>{{ idx + 1 }}</td>
              <td>{{ vo.cntrSzCdNm }}</td>
              <td>{{ vo.cntrTypCd }}</td>
              <td>{{ vo.cgoTypCdNm }}</td>
              <td>
                <input
                  v-model="vo.rfSlecUseYn"
                  :id="'rfSlecUseYn_' + idx"
                  type="checkbox"
                  true-value="Y"
                  false-value="N"
                  disabled
                >
                <label :for="'rfSlecUseYn_' + idx"></label>
              </td>
              <td>{{ vo.rfSetupTmpr }}</td>
              <td>{{ vo.rfTmprUnitCd }}</td>
              <td v-if="cntrPolCtrCd === 'VN' && cntrRfStsYn === 'Y'">{{ getRfVentCdNmVN(vo.cntrRfStsCd) }}</td>
              <td v-else>{{ getRfVentCdNm(vo.cntrRfStsCd) }}</td>
            </tr>
          </tbody>
          <tbody v-if="resultList.length === 0">
            <tr>
              <td colspan="8">{{ $t('msg.CMBA660.005') }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="text_center mt20">
        <a class="button lg blue font_16" @click="fnRFSelect()">{{ $t('msg.CSBK100.178') }}</a>
        <a class="button lg gray font_16" @click="$emit('close')">{{ $t('msg.CSBK100.179') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import commons from '@/api/services/commons'
import trans from '@/api/rest/trans/trans'
import transCommon from '@/api/rest/trans/transCommon'

export default {
  name: 'BLContainerRFPop',
  props: {
    parentInfo: {
      type: Object,
      default () {
        return {
          modifyFlag: 'I',
          blNo: '',
          bkgNo: '',
          cntrSzCd: '',
          rfTmprUnitCd: '',
          rfSetupTmpr: '',
          rfSlecUseYn: '',
          cntrRfStsCd: '',
          idx: '',
          polCtrCd: '',
          kind: ''
        }
      }
    }
  },
  data () {
    return {
      commonCodes: {},
      resultList: [],
      cntrRfStsList: [],
      cntrRfStsListVN: [],
      cntrPolCtrCd: this.parentInfo.polCtrCd,
      cntrRfStsYn: 'N'
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      const arrCdId = ['01031', '01038', '01291', 'CS103']

      await commons.getCommonsCodesCommon(arrCdId).then(res => {
        console.log('getCommonsCodesCommon @@@@@ ', res)

        this.commonCodes = res
      })

      const info = this.parentInfo
      if (info.blNo !== '' || info.bkgNo !== '') {
        const params = {
          modifyFlag: info.modifyFlag,
          blNo: info.blNo,
          bkgNo: info.bkgNo
        }

        await trans.findRFInfo(params).then(res => {
          const result = res.data

          if (res.headers.respcode === 'C0000' && result !== undefined) {
            const cntrSzList = this.commonCodes['01031']
            const cgoTypCdList = this.commonCodes['01038']
            this.cntrRfStsList = this.commonCodes['01291']
            // eslint-disable-next-line dot-notation
            this.cntrRfStsListVN = this.commonCodes['CS103']

            result.forEach(c => {
              const cntrSzInfo = cntrSzList.filter(vo => vo.cd === c.cntrSzCd)
              if (cntrSzInfo !== undefined && cntrSzInfo.length > 0) {
                c.cntrSzCdNm = cntrSzInfo[0].cdNm
              }

              const cgoTypInfo = cgoTypCdList.filter(vo => vo.cd === c.cgoTypCd)
              if (cgoTypInfo !== undefined && cgoTypInfo.length > 0) {
                c.cgoTypCdNm = cgoTypInfo[0].cdNm
              }
            })

            this.resultList = result
          }
        })

        const paramsRf = {
          keyword: this.$ekmtcCommon.isNotEmpty(this.parentInfo.bkgNo) ? this.parentInfo.bkgNo : ''
        }

        await transCommon.checkCntrRfStsCodeYn(paramsRf).then((res) => {
          this.cntrRfStsYn = res.data
        })

        this.$nextTick(() => {
          const info = this.parentInfo

          const div = document.querySelector('.rf_info_pop')

          this.resultList.forEach((vo, idx) => {
            if (
              vo.rfTmprUnitCd === info.rfTmprUnitCd && vo.rfSetupTmpr === info.rfSetupTmpr &&
              vo.rfSlecUseYn === info.rfSlecUseYn && vo.cntrRfStsCd === info.cntrRfStsCd
            ) {
              div.querySelector('#rfSelect_' + idx).checked = true
            }
          })
        })
      }
    },
    fnRFSelect () {
      const div = document.querySelector('.rf_info_pop')
      const checkbox = div.querySelector('.rfSelect:checked')

      if (checkbox === null) {
        this.$ekmtcCommon.alertDefault(this.$t('art.CMATK387'))
        return
      }

      if (checkbox.length > 1) {
        this.$ekmtcCommon.alertDefault(this.$t('art.CMATK388'))
        return
      }

      const idx = checkbox.value
      const info = this.resultList[idx]

      info.selectIdx = this.parentInfo.idx

      this.$emit('selectFunc', info)
      this.$emit('close')
    },
    getRfVentCdNm (cd) {
      let cdNm = ''
      this.cntrRfStsList.forEach(c => {
        if (c.cd === cd) {
          cdNm = c.cdNm
          return false
        }
      })
      return cdNm
    },
    getRfVentCdNmVN (cd) {
      let cdNm = ''
      this.cntrRfStsListVN.forEach(c => {
        if (c.cd === cd) {
          cdNm = c.cdNm
          return false
        }
      })
      return cdNm
    }
  }
}
</script>
